/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * inojin-stg-api
 * OpenAPI spec version: 1.0.0
 */

export type PostReservationSettingsBodiesType = typeof PostReservationSettingsBodiesType[keyof typeof PostReservationSettingsBodiesType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostReservationSettingsBodiesType = {
  clinic: 'clinic',
  clinicMember: 'clinicMember',
} as const;
