import React, { useContext } from "react";

import { Navigate, useLocation } from "react-router-dom";

import { LoggedInContext } from "./authContextProvider";

type Props = {
  component: React.ReactNode;
};

export const RouteAuthGuard: React.FC<Props> = (props) => {
  const [loggedIn] = useContext(LoggedInContext);
  const { pathname } = useLocation();
  // ログインしているのにloginページへアクセスしたらtopへリダイレクトする
  if (loggedIn === true && pathname === "/login") {
    return <Navigate to={"/"} replace={false} />;
  }
  // ログインしていなかったらloginページへ
  if (loggedIn === false && pathname !== "/login") {
    return <Navigate to={"/login"} replace={false} />;
  }

  return <>{props.component}</>;
};
