import React, { useEffect, useState, useCallback } from "react";

import { LoginBodiesAuthFlow } from "api-client";
import { decodeToken } from "react-jwt";
import { useNavigate } from "react-router";

import { useLoginHook, useVerifyHook } from "../api-client/common/common";
import { useLocalStorage } from "../hooks/useLocalStorage";

/**
 * 認証情報でlocalStorageに投入したデータの型
 */
type AuthInfo = {
  idToken: string;
  accessToken: string;
  refreshToken: string;
  email: string;
  role: string;
};

type DecodedJwt = {
  "cognito:groups": string[];
  email: string;
  exp: number;
};
// ログイン状態のContext
/**
 * true: ログイン状態
 * false: ログアウト状態
 * null 未認証状態
 */
export const LoggedInContext = React.createContext<[boolean | null, () => void]>([null, () => { }]);

// 認証情報と認証情報セットのContext
export const AuthInfoContext = React.createContext<
  [
    AuthInfo,
    (
      idToken: string,
      accessToken: string,
      refreshToken: string
    ) => void,
    (email: string) => void,
    (role: string) => void
  ]
>([{ idToken: "", accessToken: "", refreshToken: "", email: "", role: "" }, () => { }, () => { }, () => { }]);

export const AuthContextProvider: React.FC<React.PropsWithChildren> = (
  props
) => {
  // stateの定義
  const [loggedIn, setLoggedIn] = useState<boolean | null>(null);
  const [authInfo, setAuthInfo] = useState<AuthInfo>({
    idToken: "",
    accessToken: "",
    refreshToken: "",
    email: "",
    role: ""
  });
  const [idToken, updateIdToken, deleteIdToken] = useLocalStorage("idToken");
  const [accessToken, updateAccessToken, deleteAccessToken] = useLocalStorage("accessToken");
  const [refreshToken, updateRefreshToken, deleteRefreshToken] = useLocalStorage("refreshToken");

  const checkVerify = useCallback(async () => {
    if (!idToken) {
      setLoggedIn(false);
      return
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const verify = useVerifyHook();
    try {
      await verify();
      setLoggedIn(true);
    } catch (error) {
      setLoggedIn(false);
      return;
    }
  }, [idToken]);

  const setToken = useCallback((
    idToken: string,
    accessToken: string,
    refreshToken: string
  ) => {
    const decodedIdToken = decodeToken<DecodedJwt>(idToken);

    if (decodedIdToken && decodedIdToken?.["cognito:groups"]?.length > 0) {
      // トークンの有効期限 (exp) を秒単位で取得
      const expirationTimestamp = decodedIdToken?.exp!; // トークンの有効期限 (exp) のタイムスタンプ
      // 現在の時間 (Unix エポックからの経過秒数)
      const currentTimestamp = Math.floor(Date.now() / 1000);
      // トークンの有効期限までの残り秒数を計算
      const remainingSeconds = expirationTimestamp - currentTimestamp;
      // 残り秒数を分単位に変換
      const remainingIdTokenMinutes = Math.floor(remainingSeconds / 60);

      console.debug('Rmaining Session minutes: ' + remainingIdTokenMinutes); // 有効期限までの残り分数

      if (remainingIdTokenMinutes <= 5) {
        setAuthInfo(() => {
          return {
            idToken,
            accessToken,
            refreshToken,
            email: decodedIdToken.email,
            role: decodedIdToken["cognito:groups"][0],
          };
        });
        extentionRefreshToken();
      } else {
        setAuthInfo(() => {
          return {
            idToken,
            accessToken,
            refreshToken,
            email: decodedIdToken.email,
            role: decodedIdToken["cognito:groups"][0],
          };
        });
        updateIdToken(idToken);
        updateAccessToken(accessToken);
        updateRefreshToken(refreshToken);
      }
    }
  },
    // eslint-disable-next-line
    [updateIdToken, updateAccessToken, updateRefreshToken]
  );

  const setEmail = useCallback((email: string) => {
    setAuthInfo((prevState) => {
      return {
        idToken: prevState.idToken,
        accessToken: prevState.accessToken,
        refreshToken: prevState.refreshToken,
        email,
        role: prevState.role,
      };
    });
  }, []);

  const setRole = useCallback((email: string) => {
    setAuthInfo((prevState) => {
      return {
        idToken: prevState.idToken,
        accessToken: prevState.accessToken,
        refreshToken: prevState.refreshToken,
        email,
        role: prevState.role,
      };
    });
  }, []);
  const extentionRefreshToken = async () => {
    try {
      const login = useLoginHook();

      const result = await login({
        refreshToken: refreshToken!,
        authFlow: LoginBodiesAuthFlow.REFRESH_TOKEN_AUTH,
      });
      if (!result?.data?.idToken && !result?.data?.accessToken && !result?.data?.refreshToken) {
        localStorage.clear();
        const navigate = useNavigate();
        navigate("/login");
        return;
      }

      if (result?.data?.idToken) {
        //updateIdToken(result.data.idToken);
        localStorage.idToken = result.data.idToken;
      }
      if (result?.data?.accessToken) {
        //updateAccessToken(result.data.accessToken);
        localStorage.accessToken = result.data.accessToken;
      }
      if (result?.data?.refreshToken) {
        //updateRefreshToken(result.data.refreshToken);
        localStorage.refreshToken = result.data.refreshToken;
      }
    } catch (e) {
      localStorage.clear();
      const navigate = useNavigate();
      navigate("/login");
    }
  };
  /**
   * 認証チェック：レンダリングされたタイミングで実行
   */
  useEffect(() => {
    checkVerify();
  }, [authInfo, checkVerify, idToken]);

  /**
   * ローカルストレージから復元したタイミングで実行
   */
  useEffect(() => {
    console.debug("setToken() [FROM]localstorage");
    if (idToken && accessToken && refreshToken) {
      setToken(idToken, accessToken, refreshToken);
    }
  }, [setToken, idToken, accessToken, refreshToken]);

  const logout = useCallback(() => {
    setLoggedIn(false);
    setAuthInfo({
      idToken: "",
      accessToken: "",
      refreshToken: "",
      email: "",
      role: "",
    });
    deleteIdToken();
    deleteAccessToken();
    deleteRefreshToken();
  }, [deleteIdToken, deleteAccessToken, deleteRefreshToken]);
  return (
    <LoggedInContext.Provider value={[loggedIn, logout]}>
      <AuthInfoContext.Provider value={[authInfo, setToken, setEmail, setRole]}>
        {props.children}
      </AuthInfoContext.Provider>
    </LoggedInContext.Provider>
  );
};
