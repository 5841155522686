import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

type progressType = {
  message?: string;
};

export const Progress = (props: progressType) => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        "& > * + *": {
          //marginLeft: props.theme.spacing(2),
        },
        position: "absolute",
        top: 250,
        marginTop: " 2vw",
      }}
    >
      <Message>{props.message}</Message>
      <CircularProgress
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          "& > * + *": {
            //marginLeft: props.theme.spacing(2),
          },
        }}
        size={100}
      />
    </Box>
  );
};
const Message = styled("div")`
  margin-top: 5vw;
  font-size: 24px;
`;
