/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * inojin-stg-api
 * OpenAPI spec version: 1.0.0
 */

export type PostPaymentItemsBodiesOperationType = typeof PostPaymentItemsBodiesOperationType[keyof typeof PostPaymentItemsBodiesOperationType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostPaymentItemsBodiesOperationType = {
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
} as const;
