// FIXME: stgで真っ白になるため一旦こちらを削除
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import { firebaseConfig } from "./config";

import { FIREBASE_APP_IDENTITY } from "../util/env";

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestForToken = () => {
  return Notification.requestPermission()
    .then((permission) => {
      if(permission === "granted") {
        return getToken(messaging, { vapidKey: FIREBASE_APP_IDENTITY })
      } else {
        throw new Error("###Notification not granted")
      }
    })
    .catch((err) => {
      console.error("###Error getting token: ", err)
      throw err;
    })
};

onMessage(messaging, ({ notification }) => {
  new Notification(notification?.title || '', {
    body: notification?.body,
    icon: notification?.icon,
  });
});

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload);
      resolve(payload);
    });
  }
);
