import { useEffect, useCallback } from "react";

import { jwtDecode } from "jwt-decode";
import {
  AccountStatus,
  IsInitLoginedContextProvider,
} from "provider/isInitLoginedContextProvider";
import { useLocation, useNavigate } from "react-router-dom";

import {
  LoginBodiesAuthFlow,
  useLoginHook,
  useVerifyHook,
} from "../api-client";
interface CustomTokenPayload {
  "cognito:groups"?: string[];
  sub?: string;
}
export const Session = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [clinicAccountStatus, clinicStatus] = IsInitLoginedContextProvider();
  const observeAuth = useCallback(async () => {
    const idToken = localStorage.getItem("idToken");

    if (!idToken) {
      localStorage.clear();
      return;
    }

    try {
      const verify = useVerifyHook();
      await verify();
    } catch (e: any) {
      const refreshToken = localStorage.getItem("refreshToken");

      if (!refreshToken) {
        localStorage.clear();
        return;
      }

      try {
        const login = useLoginHook();
        const result = await login({
          refreshToken: refreshToken,
          authFlow: LoginBodiesAuthFlow.REFRESH_TOKEN_AUTH,
        });

        if (
          result?.data?.idToken &&
          result?.data?.accessToken &&
          result?.data?.refreshToken
        ) {
          localStorage.idToken = result.data.idToken;
          localStorage.accessToken = result.data.accessToken;
          localStorage.refreshToken = result.data.refreshToken;
        }
      } catch (e) {
        localStorage.clear();
        navigate("/login");
      }
      return;
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (location.state?.isInitDone) {
      return;
    }
    const idToken = localStorage.getItem("idToken");

    if (!idToken) return;
    const decodedToken = jwtDecode<CustomTokenPayload>(idToken);
    const roles = decodedToken["cognito:groups"];
    const id = decodedToken.sub;
    localStorage.setItem("roles", roles![0]);
    localStorage.setItem("id", id!);

    if (!roles) return;
    // スタッフは設定不要のため処理をスキップ
    if (roles.includes("staff")) {
      return;
    }
    // MEMO: 通常ページを開いた時に、初期設定が完了している場場合は、初期設定ページに遷移させない
    if (
      location.pathname !== "/initial-setting" &&
      roles.includes("owner") &&
      clinicAccountStatus === AccountStatus.done &&
      clinicStatus === AccountStatus.done
    ) {
      return;
    }

    if (
      location.pathname !== "/initial-setting" &&
      !roles.includes("owner") &&
      clinicAccountStatus === AccountStatus.done
    ) {
      return;
    }

    if (
      roles.includes("owner") &&
      location.pathname === "/initial-setting" &&
      clinicAccountStatus === AccountStatus.done &&
      clinicStatus === AccountStatus.done
    ) {
      navigate("/");
      return;
    }

    if (
      !roles.includes("owner") &&
      location.pathname === "/initial-setting" &&
      clinicAccountStatus === AccountStatus.done
    ) {
      navigate("/");
      return;
    }

    // MEMO: ループ防止
    if (
      location.pathname === "/initial-setting" ||
      location.pathname === "/login"
    )
      return;
    if (
      roles.includes("owner") &&
      !(
        clinicAccountStatus === AccountStatus.done &&
        clinicStatus === AccountStatus.done
      )
    ) {
      navigate("/initial-setting");
      return;
    }
    if (
      !roles.includes("owner") &&
      clinicAccountStatus !== AccountStatus.done
    ) {
      navigate("/initial-setting");
      return;
    }
    navigate(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinicAccountStatus, clinicStatus, location.pathname, navigate]);

  useEffect(() => {
    observeAuth();
    // eslint-disable-next-line
  }, [observeAuth]);

  return <>{null}</>;
};
