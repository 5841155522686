/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * inojin-stg-api
 * OpenAPI spec version: 1.0.0
 */

export type GetClinicAccountsByEmailResponseResponseResultRole = typeof GetClinicAccountsByEmailResponseResponseResultRole[keyof typeof GetClinicAccountsByEmailResponseResponseResultRole];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetClinicAccountsByEmailResponseResponseResultRole = {
  owner: 'owner',
  doctor: 'doctor',
  staff: 'staff',
} as const;
