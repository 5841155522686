/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * inojin-stg-api
 * OpenAPI spec version: 1.0.0
 */
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  GetClinicsResponseResponse,
  BadRequestResponse,
  InternalServerErrorResponse,
  SuccessResponseResponse,
  PatchAccountPasswordByAdminRoleBodies,
  FlagResponseResponse,
  GetClinicAccountsByClinicIdResponseResponse,
  GetClinicOpeningSettingDisableTimeResponseResponse,
  GetClinicConsultationHoursSettingsDisableTimeParams,
  GetClinicOpeningSettingResponseResponse,
  GetReservationSlots200,
  GetReservationSlotsParams,
  PostReservationSlotsForPost200,
  PostReservationSlotsForPostBodies,
  SuccessResponse,
  PutPatientInsuranceCardsBodies,
  PutPatientAdditionalDocumentsBodies,
  GetPaymentResponseResponse,
  SignedUrl200,
  SignedUrlParams,
  GetConsultations200,
  GetConsultationsParams,
  GetSearchConsultations200,
  GetSearchConsultationsParams,
  GetChangePatientInfoFlag200,
  GetChangePatientInfoFlagParams,
  GetChangePatientInfoFlagByPatientId200,
  GetChangePatientInfoFlagByPatientIdParams,
  GetSearchPatients200,
  GetSearchPatientsParams,
  GetConsultationPatientModelDTO,
  PatchConsultationBodies,
  PatchConsultationsIsFollowUpInstructionBodies,
  PatchMedicalInterviewInstancesPatientEyesImageUrlBodies,
  PatchConsultationsStatusBodies,
  PatchConsultationsRoomResponseResponse,
  PatchConsultationsRoomBodies,
  SuccessPostResponseIdResponse,
  PostShippingAddressesBodies,
  GetShippingAddressesByPatientId200,
  PatchShippingAddressesBodies,
  LoginResponseResponse,
  LoginBodies,
  AuthChallengeBodies,
  DataOKResponseResponse,
  ResetUserPasswordBodies,
  ConfirmForgotPasswordBodies,
  LogoutBodies,
  VerifyResendAuthenticationCodeBodies,
  PostOnlineConsultationResponseResponse,
  PostOnlineConsultationBodies,
  PatchPatientBodyBodies,
  GetPatientResponseResponse,
  PatchMedicalInterviewInstancesBodies,
  GetZipcodeResponseResponse,
  GetZipcodeParams,
  GetMasterCancelReasonsResponseResponse
} from '.././model'
import { useCustomClient } from '../../apiClientConfig/customClient';
import type { ErrorType, BodyType } from '../../apiClientConfig/customClient';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


/**
 * # 仕様
- クリニックのリストを返す

 * @summary クリニック一覧取得API
 */
export const useGetClinicsHook = () => {
        const getClinics = useCustomClient<GetClinicsResponseResponse>();

        return (
    
 signal?: AbortSignal
) => {
        return getClinics(
          {url: `/v1/clinics`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetClinicsQueryKey = () => [`/v1/clinics`] as const;
  

    
export const useGetClinicsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetClinicsHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetClinicsHook>>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetClinicsHook>>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetClinicsQueryKey();

  const getClinics =  useGetClinicsHook();
  
    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetClinicsHook>>>> = ({ signal }) => getClinics(signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetClinicsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetClinicsHook>>>>
export type GetClinicsQueryError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

/**
 * @summary クリニック一覧取得API
 */
export const useGetClinics = <TData = Awaited<ReturnType<ReturnType<typeof useGetClinicsHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetClinicsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetClinicsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * # 仕様
- emailに紐づくアカウント情報のパスワードをAdmin権限で更新する
- パスワードは8文字以上とする

 * @summary Admin権限でアカウントパスワードを更新するAPI
 */
export const usePatchAccountPasswordByAdminRoleHook = () => {
        const patchAccountPasswordByAdminRole = useCustomClient<SuccessResponseResponse>();

        return (
    patchAccountPasswordByAdminRoleBodies: BodyType<PatchAccountPasswordByAdminRoleBodies>,
 ) => {
        return patchAccountPasswordByAdminRole(
          {url: `/v1/admins/changePasswordByAdminRole`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchAccountPasswordByAdminRoleBodies
    },
          );
        }
      }
    


export const usePatchAccountPasswordByAdminRoleMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchAccountPasswordByAdminRoleHook>>>, TError,{data: BodyType<PatchAccountPasswordByAdminRoleBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchAccountPasswordByAdminRoleHook>>>, TError,{data: BodyType<PatchAccountPasswordByAdminRoleBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const patchAccountPasswordByAdminRole =  usePatchAccountPasswordByAdminRoleHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePatchAccountPasswordByAdminRoleHook>>>, {data: BodyType<PatchAccountPasswordByAdminRoleBodies>}> = (props) => {
          const {data} = props ?? {};

          return  patchAccountPasswordByAdminRole(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PatchAccountPasswordByAdminRoleMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePatchAccountPasswordByAdminRoleHook>>>>
    export type PatchAccountPasswordByAdminRoleMutationBody = BodyType<PatchAccountPasswordByAdminRoleBodies>
    export type PatchAccountPasswordByAdminRoleMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary Admin権限でアカウントパスワードを更新するAPI
 */
export const usePatchAccountPasswordByAdminRole = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchAccountPasswordByAdminRoleHook>>>, TError,{data: BodyType<PatchAccountPasswordByAdminRoleBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePatchAccountPasswordByAdminRoleMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- ユーザの有効化または無効化を確認する
 * @summary ユーザの有効化・無効化確認API
 */
export const useAdminGetUserStatusHook = () => {
        const adminGetUserStatus = useCustomClient<FlagResponseResponse>();

        return (
    userId: string,
 signal?: AbortSignal
) => {
        return adminGetUserStatus(
          {url: `/v1/clinics/adminGetUserStatus/${userId}`, method: 'get', signal
    },
          );
        }
      }
    

export const getAdminGetUserStatusQueryKey = (userId: string,) => [`/v1/clinics/adminGetUserStatus/${userId}`] as const;
  

    
export const useAdminGetUserStatusQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useAdminGetUserStatusHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useAdminGetUserStatusHook>>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useAdminGetUserStatusHook>>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAdminGetUserStatusQueryKey(userId);

  const adminGetUserStatus =  useAdminGetUserStatusHook();
  
    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useAdminGetUserStatusHook>>>> = ({ signal }) => adminGetUserStatus(userId, signal);
    
      
      
   return  { queryKey, queryFn, enabled: !!(userId), ...queryOptions}}

export type AdminGetUserStatusQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAdminGetUserStatusHook>>>>
export type AdminGetUserStatusQueryError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

/**
 * @summary ユーザの有効化・無効化確認API
 */
export const useAdminGetUserStatus = <TData = Awaited<ReturnType<ReturnType<typeof useAdminGetUserStatusHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(
 userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useAdminGetUserStatusHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useAdminGetUserStatusQueryOptions(userId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * # 仕様
クリニックアカウントの一覧を取得する

 * @summary クリニックアカウント一覧取得API
 */
export const useGetClinicAccountsHook = () => {
        const getClinicAccounts = useCustomClient<GetClinicAccountsByClinicIdResponseResponse>();

        return (
    
 signal?: AbortSignal
) => {
        return getClinicAccounts(
          {url: `/v1/clinicAccounts`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetClinicAccountsQueryKey = () => [`/v1/clinicAccounts`] as const;
  

    
export const useGetClinicAccountsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetClinicAccountsHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetClinicAccountsHook>>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetClinicAccountsHook>>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetClinicAccountsQueryKey();

  const getClinicAccounts =  useGetClinicAccountsHook();
  
    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetClinicAccountsHook>>>> = ({ signal }) => getClinicAccounts(signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetClinicAccountsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetClinicAccountsHook>>>>
export type GetClinicAccountsQueryError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

/**
 * @summary クリニックアカウント一覧取得API
 */
export const useGetClinicAccounts = <TData = Awaited<ReturnType<ReturnType<typeof useGetClinicAccountsHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetClinicAccountsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetClinicAccountsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * # 仕様
- requestParameterのIDに紐づくスケジュール設定曜日別予約可能時間を1件返す

 * @summary 医療機関診療時間予約不可時間設定取得API
 */
export const useGetClinicConsultationHoursSettingsDisableTimeHook = () => {
        const getClinicConsultationHoursSettingsDisableTime = useCustomClient<GetClinicOpeningSettingDisableTimeResponseResponse>();

        return (
    clinicId: string,
    params: GetClinicConsultationHoursSettingsDisableTimeParams,
 signal?: AbortSignal
) => {
        return getClinicConsultationHoursSettingsDisableTime(
          {url: `/v1/consultationHoursSettings/${clinicId}/disableTime`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetClinicConsultationHoursSettingsDisableTimeQueryKey = (clinicId: string,
    params: GetClinicConsultationHoursSettingsDisableTimeParams,) => [`/v1/consultationHoursSettings/${clinicId}/disableTime`, ...(params ? [params]: [])] as const;
  

    
export const useGetClinicConsultationHoursSettingsDisableTimeQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetClinicConsultationHoursSettingsDisableTimeHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(clinicId: string,
    params: GetClinicConsultationHoursSettingsDisableTimeParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetClinicConsultationHoursSettingsDisableTimeHook>>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetClinicConsultationHoursSettingsDisableTimeHook>>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetClinicConsultationHoursSettingsDisableTimeQueryKey(clinicId,params);

  const getClinicConsultationHoursSettingsDisableTime =  useGetClinicConsultationHoursSettingsDisableTimeHook();
  
    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetClinicConsultationHoursSettingsDisableTimeHook>>>> = ({ signal }) => getClinicConsultationHoursSettingsDisableTime(clinicId,params, signal);
    
      
      
   return  { queryKey, queryFn, enabled: !!(clinicId), ...queryOptions}}

export type GetClinicConsultationHoursSettingsDisableTimeQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetClinicConsultationHoursSettingsDisableTimeHook>>>>
export type GetClinicConsultationHoursSettingsDisableTimeQueryError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

/**
 * @summary 医療機関診療時間予約不可時間設定取得API
 */
export const useGetClinicConsultationHoursSettingsDisableTime = <TData = Awaited<ReturnType<ReturnType<typeof useGetClinicConsultationHoursSettingsDisableTimeHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(
 clinicId: string,
    params: GetClinicConsultationHoursSettingsDisableTimeParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetClinicConsultationHoursSettingsDisableTimeHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetClinicConsultationHoursSettingsDisableTimeQueryOptions(clinicId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * # 仕様
- requestParameterのIDに紐づくスケジュール設定曜日別予約可能時間を1件返す

 * @summary 医療機関診療時間設定取得API
 */
export const useGetClinicConsultationHoursSettingHook = () => {
        const getClinicConsultationHoursSetting = useCustomClient<GetClinicOpeningSettingResponseResponse>();

        return (
    clinicId: string,
 signal?: AbortSignal
) => {
        return getClinicConsultationHoursSetting(
          {url: `/v1/consultationHoursSettings/${clinicId}`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetClinicConsultationHoursSettingQueryKey = (clinicId: string,) => [`/v1/consultationHoursSettings/${clinicId}`] as const;
  

    
export const useGetClinicConsultationHoursSettingQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetClinicConsultationHoursSettingHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(clinicId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetClinicConsultationHoursSettingHook>>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetClinicConsultationHoursSettingHook>>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetClinicConsultationHoursSettingQueryKey(clinicId);

  const getClinicConsultationHoursSetting =  useGetClinicConsultationHoursSettingHook();
  
    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetClinicConsultationHoursSettingHook>>>> = ({ signal }) => getClinicConsultationHoursSetting(clinicId, signal);
    
      
      
   return  { queryKey, queryFn, enabled: !!(clinicId), ...queryOptions}}

export type GetClinicConsultationHoursSettingQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetClinicConsultationHoursSettingHook>>>>
export type GetClinicConsultationHoursSettingQueryError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

/**
 * @summary 医療機関診療時間設定取得API
 */
export const useGetClinicConsultationHoursSetting = <TData = Awaited<ReturnType<ReturnType<typeof useGetClinicConsultationHoursSettingHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(
 clinicId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetClinicConsultationHoursSettingHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetClinicConsultationHoursSettingQueryOptions(clinicId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * # 仕様
- 対象のユーザーのID、取得範囲の予約枠を取得する
- 患者予約画面、医療機関マイページ、医師詳細画面での使用を想定

 * @summary 予約枠取得API
 */
export const useGetReservationSlotsHook = () => {
        const getReservationSlots = useCustomClient<GetReservationSlots200>();

        return (
    params?: GetReservationSlotsParams,
 signal?: AbortSignal
) => {
        return getReservationSlots(
          {url: `/v1/reservation/slots`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetReservationSlotsQueryKey = (params?: GetReservationSlotsParams,) => [`/v1/reservation/slots`, ...(params ? [params]: [])] as const;
  

    
export const useGetReservationSlotsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetReservationSlotsHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(params?: GetReservationSlotsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetReservationSlotsHook>>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetReservationSlotsHook>>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetReservationSlotsQueryKey(params);

  const getReservationSlots =  useGetReservationSlotsHook();
  
    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetReservationSlotsHook>>>> = ({ signal }) => getReservationSlots(params, signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetReservationSlotsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetReservationSlotsHook>>>>
export type GetReservationSlotsQueryError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

/**
 * @summary 予約枠取得API
 */
export const useGetReservationSlots = <TData = Awaited<ReturnType<ReturnType<typeof useGetReservationSlotsHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(
 params?: GetReservationSlotsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetReservationSlotsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetReservationSlotsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * # 仕様
- 対象のユーザーのID、取得範囲の予約枠を取得する
- 患者予約画面、医療機関マイページ、医師詳細画面での使用を想定
- /v1/reservation/slotのgetリクエストのpostリクエスト版

 * @summary 予約枠取得API
 */
export const usePostReservationSlotsForPostHook = () => {
        const postReservationSlotsForPost = useCustomClient<PostReservationSlotsForPost200>();

        return (
    postReservationSlotsForPostBodies: BodyType<PostReservationSlotsForPostBodies>,
 ) => {
        return postReservationSlotsForPost(
          {url: `/v1/reservation/slotsForPost`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: postReservationSlotsForPostBodies
    },
          );
        }
      }
    


export const usePostReservationSlotsForPostMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostReservationSlotsForPostHook>>>, TError,{data: BodyType<PostReservationSlotsForPostBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostReservationSlotsForPostHook>>>, TError,{data: BodyType<PostReservationSlotsForPostBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const postReservationSlotsForPost =  usePostReservationSlotsForPostHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostReservationSlotsForPostHook>>>, {data: BodyType<PostReservationSlotsForPostBodies>}> = (props) => {
          const {data} = props ?? {};

          return  postReservationSlotsForPost(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostReservationSlotsForPostMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostReservationSlotsForPostHook>>>>
    export type PostReservationSlotsForPostMutationBody = BodyType<PostReservationSlotsForPostBodies>
    export type PostReservationSlotsForPostMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary 予約枠取得API
 */
export const usePostReservationSlotsForPost = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostReservationSlotsForPostHook>>>, TError,{data: BodyType<PostReservationSlotsForPostBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePostReservationSlotsForPostMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- 保険証画像（表裏）のURLを登録する
  - 表裏どちらか一方のみ登録する場合もある
  - ロジックはCREATEではなくUPDATE
- TODO: S3イベント通知をトリガーにLambdaを実行でき次第、このAPIを削除する
  - クライアントにて、署名付きURLによる保険証画像のアップロードが完了した後、このAPIを呼び出す
  - 本来はS3のイベント通知をトリガーにLambdaを呼び出す形で実装したほうが好ましい

# 画面URL
- https://onl.la/HRnRYbq
- https://onl.tw/96M3nc7
 * @summary 保険証画像URL登録API
 */
export const usePutPatientInsuranceCardsHook = () => {
        const putPatientInsuranceCards = useCustomClient<SuccessResponse>();

        return (
    putPatientInsuranceCardsBodies: BodyType<PutPatientInsuranceCardsBodies>,
 ) => {
        return putPatientInsuranceCards(
          {url: `/v1/patient/insurance_cards`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: putPatientInsuranceCardsBodies
    },
          );
        }
      }
    


export const usePutPatientInsuranceCardsMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePutPatientInsuranceCardsHook>>>, TError,{data: BodyType<PutPatientInsuranceCardsBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePutPatientInsuranceCardsHook>>>, TError,{data: BodyType<PutPatientInsuranceCardsBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const putPatientInsuranceCards =  usePutPatientInsuranceCardsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePutPatientInsuranceCardsHook>>>, {data: BodyType<PutPatientInsuranceCardsBodies>}> = (props) => {
          const {data} = props ?? {};

          return  putPatientInsuranceCards(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PutPatientInsuranceCardsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePutPatientInsuranceCardsHook>>>>
    export type PutPatientInsuranceCardsMutationBody = BodyType<PutPatientInsuranceCardsBodies>
    export type PutPatientInsuranceCardsMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary 保険証画像URL登録API
 */
export const usePutPatientInsuranceCards = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePutPatientInsuranceCardsHook>>>, TError,{data: BodyType<PutPatientInsuranceCardsBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePutPatientInsuranceCardsMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- 患者の医療証などの保険証以外の画像を登録する
  - ロジックはCREATEではなくUPDATE
- TODO: S3イベント通知をトリガーにLambdaを実行でき次第、このAPIを削除する
  - クライアントにて、署名付きURLによる画像のアップロードが完了した後、このAPIを呼び出す
  - 本来はS3のイベント通知をトリガーにLambdaを呼び出す形で実装したほうが好ましい

# 画面URL
- https://onl.la/HRnRYbq
- https://onl.tw/96M3nc7
 * @summary 保険証画像URL登録API
 */
export const usePutPatientAdditionalDocumentsHook = () => {
        const putPatientAdditionalDocuments = useCustomClient<SuccessResponse>();

        return (
    putPatientAdditionalDocumentsBodies: BodyType<PutPatientAdditionalDocumentsBodies>,
 ) => {
        return putPatientAdditionalDocuments(
          {url: `/v1/patient/additional_documents`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: putPatientAdditionalDocumentsBodies
    },
          );
        }
      }
    


export const usePutPatientAdditionalDocumentsMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePutPatientAdditionalDocumentsHook>>>, TError,{data: BodyType<PutPatientAdditionalDocumentsBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePutPatientAdditionalDocumentsHook>>>, TError,{data: BodyType<PutPatientAdditionalDocumentsBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const putPatientAdditionalDocuments =  usePutPatientAdditionalDocumentsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePutPatientAdditionalDocumentsHook>>>, {data: BodyType<PutPatientAdditionalDocumentsBodies>}> = (props) => {
          const {data} = props ?? {};

          return  putPatientAdditionalDocuments(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PutPatientAdditionalDocumentsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePutPatientAdditionalDocumentsHook>>>>
    export type PutPatientAdditionalDocumentsMutationBody = BodyType<PutPatientAdditionalDocumentsBodies>
    export type PutPatientAdditionalDocumentsMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary 保険証画像URL登録API
 */
export const usePutPatientAdditionalDocuments = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePutPatientAdditionalDocumentsHook>>>, TError,{data: BodyType<PutPatientAdditionalDocumentsBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePutPatientAdditionalDocumentsMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- consulationIdによってpaymentを取得する
 * @summary consulationIdによってpaymentを取得するAPI
 */
export const useGetPaymentByConsultationIdHook = () => {
        const getPaymentByConsultationId = useCustomClient<GetPaymentResponseResponse>();

        return (
    consultationId: string,
 signal?: AbortSignal
) => {
        return getPaymentByConsultationId(
          {url: `/v1/payment/consultationId/${consultationId}`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetPaymentByConsultationIdQueryKey = (consultationId: string,) => [`/v1/payment/consultationId/${consultationId}`] as const;
  

    
export const useGetPaymentByConsultationIdQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetPaymentByConsultationIdHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(consultationId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPaymentByConsultationIdHook>>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPaymentByConsultationIdHook>>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPaymentByConsultationIdQueryKey(consultationId);

  const getPaymentByConsultationId =  useGetPaymentByConsultationIdHook();
  
    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetPaymentByConsultationIdHook>>>> = ({ signal }) => getPaymentByConsultationId(consultationId, signal);
    
      
      
   return  { queryKey, queryFn, enabled: !!(consultationId), ...queryOptions}}

export type GetPaymentByConsultationIdQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetPaymentByConsultationIdHook>>>>
export type GetPaymentByConsultationIdQueryError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

/**
 * @summary consulationIdによってpaymentを取得するAPI
 */
export const useGetPaymentByConsultationId = <TData = Awaited<ReturnType<ReturnType<typeof useGetPaymentByConsultationIdHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(
 consultationId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPaymentByConsultationIdHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetPaymentByConsultationIdQueryOptions(consultationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * # 仕様
- 対象urlを渡し、署名付きのダウンロードURL、アップロードURLを取得する
 * @summary 署名付きのダウンロードURL、アップロードURLを取得するAPI
 */
export const useSignedUrlHook = () => {
        const signedUrl = useCustomClient<SignedUrl200>();

        return (
    params: SignedUrlParams,
 signal?: AbortSignal
) => {
        return signedUrl(
          {url: `/v1/signedUrl/`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getSignedUrlQueryKey = (params: SignedUrlParams,) => [`/v1/signedUrl/`, ...(params ? [params]: [])] as const;
  

    
export const useSignedUrlQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useSignedUrlHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(params: SignedUrlParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useSignedUrlHook>>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useSignedUrlHook>>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSignedUrlQueryKey(params);

  const signedUrl =  useSignedUrlHook();
  
    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useSignedUrlHook>>>> = ({ signal }) => signedUrl(params, signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type SignedUrlQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSignedUrlHook>>>>
export type SignedUrlQueryError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

/**
 * @summary 署名付きのダウンロードURL、アップロードURLを取得するAPI
 */
export const useSignedUrl = <TData = Awaited<ReturnType<ReturnType<typeof useSignedUrlHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(
 params: SignedUrlParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useSignedUrlHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useSignedUrlQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * 医師：診察一覧画面での使用を想定、typeにclinicを指定する。
必ず医師を特定する。
clinicAccountIdがリクエストに含まれている場合は、その医師の診察情報をリストで返す。
clinicAccountIdが指定されていない場合は、jwtから自身のclinicAccountIDを取得し、所属するクリニックでの診察情報を返す
https://www.figma.com/file/JuCk6DDPjoaDO4S4mdUm8A/Innojin?type=design&node-id=81-2465&mode=design&t=1SxIsqsZTrsZNe8h-4
患者：診察一覧画面での使用を想定、typeにpatientを指定する。jwtから自身のpatientIDを取得し、自身の診察情報をリストで返す
https://www.figma.com/file/wvQAppX0dXEgfILXCpWCMJ/%F0%9F%8F%A5%F0%9F%91%A8%E2%80%8D%E2%9A%95%EF%B8%8F%F0%9F%91%A9%E2%80%8D%E2%9A%95%EF%B8%8F%F0%9F%8F%A5?type=design&node-id=20-2&mode=design&t=HooYZdtAqTEnf4CZ-4
 * @summary 診察リスト取得API
 */
export const useGetConsultationsHook = () => {
        const getConsultations = useCustomClient<GetConsultations200>();

        return (
    params?: GetConsultationsParams,
 signal?: AbortSignal
) => {
        return getConsultations(
          {url: `/v1/consultations`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetConsultationsQueryKey = (params?: GetConsultationsParams,) => [`/v1/consultations`, ...(params ? [params]: [])] as const;
  

    
export const useGetConsultationsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetConsultationsHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(params?: GetConsultationsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetConsultationsHook>>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetConsultationsHook>>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetConsultationsQueryKey(params);

  const getConsultations =  useGetConsultationsHook();
  
    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetConsultationsHook>>>> = ({ signal }) => getConsultations(params, signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetConsultationsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetConsultationsHook>>>>
export type GetConsultationsQueryError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

/**
 * @summary 診察リスト取得API
 */
export const useGetConsultations = <TData = Awaited<ReturnType<ReturnType<typeof useGetConsultationsHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(
 params?: GetConsultationsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetConsultationsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetConsultationsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * 医師：診察一覧画面での使用を想定、typeにclinicを指定する。
必ず医師を特定する。
clinicAccountIdがリクエストに含まれている場合は、その医師の診察情報をリストで返す。
clinicAccountIdが指定されていない場合は、jwtから自身のclinicAccountIDを取得し、所属するクリニックでの診察情報を返す
https://www.figma.com/file/JuCk6DDPjoaDO4S4mdUm8A/Innojin?type=design&node-id=81-2465&mode=design&t=1SxIsqsZTrsZNe8h-4
患者：診察一覧画面での使用を想定、typeにpatientを指定する。jwtから自身のpatientIDを取得し、自身の診察情報をリストで返す
https://www.figma.com/file/wvQAppX0dXEgfILXCpWCMJ/%F0%9F%8F%A5%F0%9F%91%A8%E2%80%8D%E2%9A%95%EF%B8%8F%F0%9F%91%A9%E2%80%8D%E2%9A%95%EF%B8%8F%F0%9F%8F%A5?type=design&node-id=20-2&mode=design&t=HooYZdtAqTEnf4CZ-4
 * @summary 診察リスト取得API
 */
export const useGetSearchConsultationsHook = () => {
        const getSearchConsultations = useCustomClient<GetSearchConsultations200>();

        return (
    params?: GetSearchConsultationsParams,
 signal?: AbortSignal
) => {
        return getSearchConsultations(
          {url: `/v1/searchConsultations`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetSearchConsultationsQueryKey = (params?: GetSearchConsultationsParams,) => [`/v1/searchConsultations`, ...(params ? [params]: [])] as const;
  

    
export const useGetSearchConsultationsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetSearchConsultationsHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(params?: GetSearchConsultationsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSearchConsultationsHook>>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSearchConsultationsHook>>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSearchConsultationsQueryKey(params);

  const getSearchConsultations =  useGetSearchConsultationsHook();
  
    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetSearchConsultationsHook>>>> = ({ signal }) => getSearchConsultations(params, signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetSearchConsultationsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetSearchConsultationsHook>>>>
export type GetSearchConsultationsQueryError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

/**
 * @summary 診察リスト取得API
 */
export const useGetSearchConsultations = <TData = Awaited<ReturnType<ReturnType<typeof useGetSearchConsultationsHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(
 params?: GetSearchConsultationsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSearchConsultationsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetSearchConsultationsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * クリニック側の各患者情報に変更がある場合、changePatientInfoFlagを1で返す。
各患者情報が変更されている患者のpatientId（changePatientIdList）を配列で返す。
各患者情報が変更されている患者の数（changePatientInfoCount）を返す。

-- 以下はgetConsultationsの取得説明 --
医師：診察一覧画面での使用を想定、typeにclinicを指定する。
必ず医師を特定する。
clinicAccountIdがリクエストに含まれている場合は、その医師の診察情報をリストで返す。
clinicAccountIdが指定されていない場合は、jwtから自身のclinicAccountIDを取得し、所属するクリニックでの診察情報を返す
https://www.figma.com/file/JuCk6DDPjoaDO4S4mdUm8A/Innojin?type=design&node-id=81-2465&mode=design&t=1SxIsqsZTrsZNe8h-4
患者：診察一覧画面での使用を想定、typeにpatientを指定する。jwtから自身のpatientIDを取得し、自身の診察情報をリストで返す
https://www.figma.com/file/wvQAppX0dXEgfILXCpWCMJ/%F0%9F%8F%A5%F0%9F%91%A8%E2%80%8D%E2%9A%95%EF%B8%8F%F0%9F%91%A9%E2%80%8D%E2%9A%95%EF%B8%8F%F0%9F%8F%A5?type=design&node-id=20-2&mode=design&t=HooYZdtAqTEnf4CZ-4
 * @summary クリニック側の患者情報変更フラグ返却API
 */
export const useGetChangePatientInfoFlagHook = () => {
        const getChangePatientInfoFlag = useCustomClient<GetChangePatientInfoFlag200>();

        return (
    params?: GetChangePatientInfoFlagParams,
 signal?: AbortSignal
) => {
        return getChangePatientInfoFlag(
          {url: `/v1/changePatientInfoFlag`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetChangePatientInfoFlagQueryKey = (params?: GetChangePatientInfoFlagParams,) => [`/v1/changePatientInfoFlag`, ...(params ? [params]: [])] as const;
  

    
export const useGetChangePatientInfoFlagQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetChangePatientInfoFlagHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(params?: GetChangePatientInfoFlagParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetChangePatientInfoFlagHook>>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetChangePatientInfoFlagHook>>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetChangePatientInfoFlagQueryKey(params);

  const getChangePatientInfoFlag =  useGetChangePatientInfoFlagHook();
  
    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetChangePatientInfoFlagHook>>>> = ({ signal }) => getChangePatientInfoFlag(params, signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetChangePatientInfoFlagQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetChangePatientInfoFlagHook>>>>
export type GetChangePatientInfoFlagQueryError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

/**
 * @summary クリニック側の患者情報変更フラグ返却API
 */
export const useGetChangePatientInfoFlag = <TData = Awaited<ReturnType<ReturnType<typeof useGetChangePatientInfoFlagHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(
 params?: GetChangePatientInfoFlagParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetChangePatientInfoFlagHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetChangePatientInfoFlagQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * クリニック側の特定の患者情報に変更がある場合、changePatientInfoFlagを1で返す。

-- 以下はgetConsultationsの取得説明 --
医師：診察一覧画面での使用を想定、typeにclinicを指定する。
必ず医師を特定する。
clinicAccountIdがリクエストに含まれている場合は、その医師の診察情報をリストで返す。
clinicAccountIdが指定されていない場合は、jwtから自身のclinicAccountIDを取得し、所属するクリニックでの診察情報を返す
https://www.figma.com/file/JuCk6DDPjoaDO4S4mdUm8A/Innojin?type=design&node-id=81-2465&mode=design&t=1SxIsqsZTrsZNe8h-4
患者：診察一覧画面での使用を想定、typeにpatientを指定する。jwtから自身のpatientIDを取得し、自身の診察情報をリストで返す
https://www.figma.com/file/wvQAppX0dXEgfILXCpWCMJ/%F0%9F%8F%A5%F0%9F%91%A8%E2%80%8D%E2%9A%95%EF%B8%8F%F0%9F%91%A9%E2%80%8D%E2%9A%95%EF%B8%8F%F0%9F%8F%A5?type=design&node-id=20-2&mode=design&t=HooYZdtAqTEnf4CZ-4
 * @summary クリニック側の患者情報変更フラグ返却API
 */
export const useGetChangePatientInfoFlagByPatientIdHook = () => {
        const getChangePatientInfoFlagByPatientId = useCustomClient<GetChangePatientInfoFlagByPatientId200>();

        return (
    params?: GetChangePatientInfoFlagByPatientIdParams,
 signal?: AbortSignal
) => {
        return getChangePatientInfoFlagByPatientId(
          {url: `/v1/changePatientInfoFlagByPatientId`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetChangePatientInfoFlagByPatientIdQueryKey = (params?: GetChangePatientInfoFlagByPatientIdParams,) => [`/v1/changePatientInfoFlagByPatientId`, ...(params ? [params]: [])] as const;
  

    
export const useGetChangePatientInfoFlagByPatientIdQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetChangePatientInfoFlagByPatientIdHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(params?: GetChangePatientInfoFlagByPatientIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetChangePatientInfoFlagByPatientIdHook>>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetChangePatientInfoFlagByPatientIdHook>>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetChangePatientInfoFlagByPatientIdQueryKey(params);

  const getChangePatientInfoFlagByPatientId =  useGetChangePatientInfoFlagByPatientIdHook();
  
    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetChangePatientInfoFlagByPatientIdHook>>>> = ({ signal }) => getChangePatientInfoFlagByPatientId(params, signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetChangePatientInfoFlagByPatientIdQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetChangePatientInfoFlagByPatientIdHook>>>>
export type GetChangePatientInfoFlagByPatientIdQueryError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

/**
 * @summary クリニック側の患者情報変更フラグ返却API
 */
export const useGetChangePatientInfoFlagByPatientId = <TData = Awaited<ReturnType<ReturnType<typeof useGetChangePatientInfoFlagByPatientIdHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(
 params?: GetChangePatientInfoFlagByPatientIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetChangePatientInfoFlagByPatientIdHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetChangePatientInfoFlagByPatientIdQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * 患者一覧画面での使用を想定
 * @summary リスト取得API
 */
export const useGetSearchPatientsHook = () => {
        const getSearchPatients = useCustomClient<GetSearchPatients200>();

        return (
    params?: GetSearchPatientsParams,
 signal?: AbortSignal
) => {
        return getSearchPatients(
          {url: `/v1/searchPatients`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetSearchPatientsQueryKey = (params?: GetSearchPatientsParams,) => [`/v1/searchPatients`, ...(params ? [params]: [])] as const;
  

    
export const useGetSearchPatientsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetSearchPatientsHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(params?: GetSearchPatientsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSearchPatientsHook>>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSearchPatientsHook>>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSearchPatientsQueryKey(params);

  const getSearchPatients =  useGetSearchPatientsHook();
  
    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetSearchPatientsHook>>>> = ({ signal }) => getSearchPatients(params, signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetSearchPatientsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetSearchPatientsHook>>>>
export type GetSearchPatientsQueryError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

/**
 * @summary リスト取得API
 */
export const useGetSearchPatients = <TData = Awaited<ReturnType<ReturnType<typeof useGetSearchPatientsHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(
 params?: GetSearchPatientsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSearchPatientsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetSearchPatientsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * 医師：診察詳細画面での使用を想定
患者：診察詳細画面での使用を想定
 * @summary 診察取得API
 */
export const useGetConsultationHook = () => {
        const getConsultation = useCustomClient<GetConsultationPatientModelDTO>();

        return (
    id: string,
 signal?: AbortSignal
) => {
        return getConsultation(
          {url: `/v1/consultations/${id}`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetConsultationQueryKey = (id: string,) => [`/v1/consultations/${id}`] as const;
  

    
export const useGetConsultationQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetConsultationHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetConsultationHook>>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetConsultationHook>>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetConsultationQueryKey(id);

  const getConsultation =  useGetConsultationHook();
  
    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetConsultationHook>>>> = ({ signal }) => getConsultation(id, signal);
    
      
      
   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions}}

export type GetConsultationQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetConsultationHook>>>>
export type GetConsultationQueryError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

/**
 * @summary 診察取得API
 */
export const useGetConsultation = <TData = Awaited<ReturnType<ReturnType<typeof useGetConsultationHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetConsultationHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetConsultationQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * 医師：オンライン診察画面での使用を想定
予約日、予約時間が変更された際は、予約枠の重複チェックを行い、予約変更が不可の場合はエラーを返す。
statusの更新は副作用を伴うためstatus更新APIを使用してください
https://www.figma.com/file/JuCk6DDPjoaDO4S4mdUm8A/Innojin?type=design&node-id=211-9134&mode=design&t=2SR3FiL31toTSW2H-4
 * @summary 診察情報更新API
 */
export const usePatchConsultationHook = () => {
        const patchConsultation = useCustomClient<SuccessResponse>();

        return (
    id: string,
    patchConsultationBodies: BodyType<PatchConsultationBodies>,
 ) => {
        return patchConsultation(
          {url: `/v1/consultations/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchConsultationBodies
    },
          );
        }
      }
    


export const usePatchConsultationMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchConsultationHook>>>, TError,{id: string;data: BodyType<PatchConsultationBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchConsultationHook>>>, TError,{id: string;data: BodyType<PatchConsultationBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const patchConsultation =  usePatchConsultationHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePatchConsultationHook>>>, {id: string;data: BodyType<PatchConsultationBodies>}> = (props) => {
          const {id,data} = props ?? {};

          return  patchConsultation(id,data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PatchConsultationMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePatchConsultationHook>>>>
    export type PatchConsultationMutationBody = BodyType<PatchConsultationBodies>
    export type PatchConsultationMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary 診察情報更新API
 */
export const usePatchConsultation = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchConsultationHook>>>, TError,{id: string;data: BodyType<PatchConsultationBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePatchConsultationMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * 再診予約の状態を更新する
 * @summary 再診状態更新API
 */
export const usePatchConsultationsIsFollowUpInstructionHook = () => {
        const patchConsultationsIsFollowUpInstruction = useCustomClient<SuccessResponse>();

        return (
    patchConsultationsIsFollowUpInstructionBodies: BodyType<PatchConsultationsIsFollowUpInstructionBodies>,
 ) => {
        return patchConsultationsIsFollowUpInstruction(
          {url: `/v1/consultations/isFollowUpInstruction`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchConsultationsIsFollowUpInstructionBodies
    },
          );
        }
      }
    


export const usePatchConsultationsIsFollowUpInstructionMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchConsultationsIsFollowUpInstructionHook>>>, TError,{data: BodyType<PatchConsultationsIsFollowUpInstructionBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchConsultationsIsFollowUpInstructionHook>>>, TError,{data: BodyType<PatchConsultationsIsFollowUpInstructionBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const patchConsultationsIsFollowUpInstruction =  usePatchConsultationsIsFollowUpInstructionHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePatchConsultationsIsFollowUpInstructionHook>>>, {data: BodyType<PatchConsultationsIsFollowUpInstructionBodies>}> = (props) => {
          const {data} = props ?? {};

          return  patchConsultationsIsFollowUpInstruction(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PatchConsultationsIsFollowUpInstructionMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePatchConsultationsIsFollowUpInstructionHook>>>>
    export type PatchConsultationsIsFollowUpInstructionMutationBody = BodyType<PatchConsultationsIsFollowUpInstructionBodies>
    export type PatchConsultationsIsFollowUpInstructionMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary 再診状態更新API
 */
export const usePatchConsultationsIsFollowUpInstruction = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchConsultationsIsFollowUpInstructionHook>>>, TError,{data: BodyType<PatchConsultationsIsFollowUpInstructionBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePatchConsultationsIsFollowUpInstructionMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * 目の写真のURLをアップロードする
 * @summary 目の写真のURLアップロードAPI
 */
export const usePatchMedicalInterviewInstancesPatientEyesImageUrlHook = () => {
        const patchMedicalInterviewInstancesPatientEyesImageUrl = useCustomClient<SuccessResponse>();

        return (
    patchMedicalInterviewInstancesPatientEyesImageUrlBodies: BodyType<PatchMedicalInterviewInstancesPatientEyesImageUrlBodies>,
 ) => {
        return patchMedicalInterviewInstancesPatientEyesImageUrl(
          {url: `/v1/medicalInterviewInstances/patientEyesImageUrl`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchMedicalInterviewInstancesPatientEyesImageUrlBodies
    },
          );
        }
      }
    


export const usePatchMedicalInterviewInstancesPatientEyesImageUrlMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchMedicalInterviewInstancesPatientEyesImageUrlHook>>>, TError,{data: BodyType<PatchMedicalInterviewInstancesPatientEyesImageUrlBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchMedicalInterviewInstancesPatientEyesImageUrlHook>>>, TError,{data: BodyType<PatchMedicalInterviewInstancesPatientEyesImageUrlBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const patchMedicalInterviewInstancesPatientEyesImageUrl =  usePatchMedicalInterviewInstancesPatientEyesImageUrlHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePatchMedicalInterviewInstancesPatientEyesImageUrlHook>>>, {data: BodyType<PatchMedicalInterviewInstancesPatientEyesImageUrlBodies>}> = (props) => {
          const {data} = props ?? {};

          return  patchMedicalInterviewInstancesPatientEyesImageUrl(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PatchMedicalInterviewInstancesPatientEyesImageUrlMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePatchMedicalInterviewInstancesPatientEyesImageUrlHook>>>>
    export type PatchMedicalInterviewInstancesPatientEyesImageUrlMutationBody = BodyType<PatchMedicalInterviewInstancesPatientEyesImageUrlBodies>
    export type PatchMedicalInterviewInstancesPatientEyesImageUrlMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary 目の写真のURLアップロードAPI
 */
export const usePatchMedicalInterviewInstancesPatientEyesImageUrl = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchMedicalInterviewInstancesPatientEyesImageUrlHook>>>, TError,{data: BodyType<PatchMedicalInterviewInstancesPatientEyesImageUrlBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePatchMedicalInterviewInstancesPatientEyesImageUrlMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * 診察ステータス情報を更新する
 * @summary 診察ステータス更新API
 */
export const usePatchConsultationsStatusHook = () => {
        const patchConsultationsStatus = useCustomClient<SuccessResponse>();

        return (
    patchConsultationsStatusBodies: BodyType<PatchConsultationsStatusBodies>,
 ) => {
        return patchConsultationsStatus(
          {url: `/v1/consultations/status`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchConsultationsStatusBodies
    },
          );
        }
      }
    


export const usePatchConsultationsStatusMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchConsultationsStatusHook>>>, TError,{data: BodyType<PatchConsultationsStatusBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchConsultationsStatusHook>>>, TError,{data: BodyType<PatchConsultationsStatusBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const patchConsultationsStatus =  usePatchConsultationsStatusHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePatchConsultationsStatusHook>>>, {data: BodyType<PatchConsultationsStatusBodies>}> = (props) => {
          const {data} = props ?? {};

          return  patchConsultationsStatus(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PatchConsultationsStatusMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePatchConsultationsStatusHook>>>>
    export type PatchConsultationsStatusMutationBody = BodyType<PatchConsultationsStatusBodies>
    export type PatchConsultationsStatusMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary 診察ステータス更新API
 */
export const usePatchConsultationsStatus = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchConsultationsStatusHook>>>, TError,{data: BodyType<PatchConsultationsStatusBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePatchConsultationsStatusMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- 患者の入退室時間を更新する
- ロジックはUPSERT
  - `consultationRoomStatusId`があるかないかで判断する
 * @summary 患者入退室時間更新API
 */
export const usePatchConsultationsRoomHook = () => {
        const patchConsultationsRoom = useCustomClient<PatchConsultationsRoomResponseResponse>();

        return (
    patchConsultationsRoomBodies: BodyType<PatchConsultationsRoomBodies>,
 ) => {
        return patchConsultationsRoom(
          {url: `/v1/consultations/room`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchConsultationsRoomBodies
    },
          );
        }
      }
    


export const usePatchConsultationsRoomMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchConsultationsRoomHook>>>, TError,{data: BodyType<PatchConsultationsRoomBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchConsultationsRoomHook>>>, TError,{data: BodyType<PatchConsultationsRoomBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const patchConsultationsRoom =  usePatchConsultationsRoomHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePatchConsultationsRoomHook>>>, {data: BodyType<PatchConsultationsRoomBodies>}> = (props) => {
          const {data} = props ?? {};

          return  patchConsultationsRoom(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PatchConsultationsRoomMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePatchConsultationsRoomHook>>>>
    export type PatchConsultationsRoomMutationBody = BodyType<PatchConsultationsRoomBodies>
    export type PatchConsultationsRoomMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary 患者入退室時間更新API
 */
export const usePatchConsultationsRoom = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchConsultationsRoomHook>>>, TError,{data: BodyType<PatchConsultationsRoomBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePatchConsultationsRoomMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- 患者のお薬送付先情報を作成するAPI
 * @summary 患者のお薬送付先情報を作成するAPI
 */
export const usePostShippingAddressesHook = () => {
        const postShippingAddresses = useCustomClient<SuccessPostResponseIdResponse>();

        return (
    postShippingAddressesBodies: BodyType<PostShippingAddressesBodies>,
 ) => {
        return postShippingAddresses(
          {url: `/v1/shippingAddresses`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: postShippingAddressesBodies
    },
          );
        }
      }
    


export const usePostShippingAddressesMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostShippingAddressesHook>>>, TError,{data: BodyType<PostShippingAddressesBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostShippingAddressesHook>>>, TError,{data: BodyType<PostShippingAddressesBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const postShippingAddresses =  usePostShippingAddressesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostShippingAddressesHook>>>, {data: BodyType<PostShippingAddressesBodies>}> = (props) => {
          const {data} = props ?? {};

          return  postShippingAddresses(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostShippingAddressesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostShippingAddressesHook>>>>
    export type PostShippingAddressesMutationBody = BodyType<PostShippingAddressesBodies>
    export type PostShippingAddressesMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary 患者のお薬送付先情報を作成するAPI
 */
export const usePostShippingAddresses = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostShippingAddressesHook>>>, TError,{data: BodyType<PostShippingAddressesBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePostShippingAddressesMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * 患者のお薬送付先情報を取得するAPI
 * @summary 患者のお薬送付先情報を取得するAPI
 */
export const useGetShippingAddressesByPatientIdHook = () => {
        const getShippingAddressesByPatientId = useCustomClient<GetShippingAddressesByPatientId200>();

        return (
    patientId: string,
 signal?: AbortSignal
) => {
        return getShippingAddressesByPatientId(
          {url: `/v1/shippingAddresses/patientId/${patientId}`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetShippingAddressesByPatientIdQueryKey = (patientId: string,) => [`/v1/shippingAddresses/patientId/${patientId}`] as const;
  

    
export const useGetShippingAddressesByPatientIdQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetShippingAddressesByPatientIdHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(patientId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetShippingAddressesByPatientIdHook>>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetShippingAddressesByPatientIdHook>>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetShippingAddressesByPatientIdQueryKey(patientId);

  const getShippingAddressesByPatientId =  useGetShippingAddressesByPatientIdHook();
  
    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetShippingAddressesByPatientIdHook>>>> = ({ signal }) => getShippingAddressesByPatientId(patientId, signal);
    
      
      
   return  { queryKey, queryFn, enabled: !!(patientId), ...queryOptions}}

export type GetShippingAddressesByPatientIdQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetShippingAddressesByPatientIdHook>>>>
export type GetShippingAddressesByPatientIdQueryError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

/**
 * @summary 患者のお薬送付先情報を取得するAPI
 */
export const useGetShippingAddressesByPatientId = <TData = Awaited<ReturnType<ReturnType<typeof useGetShippingAddressesByPatientIdHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(
 patientId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetShippingAddressesByPatientIdHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetShippingAddressesByPatientIdQueryOptions(patientId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * 患者のお薬送付先情報を更新するAPI
 * @summary 患者のお薬送付先情報を更新するAPI
 */
export const usePatchShippingAddressesHook = () => {
        const patchShippingAddresses = useCustomClient<SuccessResponse>();

        return (
    id: string,
    patchShippingAddressesBodies: BodyType<PatchShippingAddressesBodies>,
 ) => {
        return patchShippingAddresses(
          {url: `/v1/shippingAddresses/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchShippingAddressesBodies
    },
          );
        }
      }
    


export const usePatchShippingAddressesMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchShippingAddressesHook>>>, TError,{id: string;data: BodyType<PatchShippingAddressesBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchShippingAddressesHook>>>, TError,{id: string;data: BodyType<PatchShippingAddressesBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const patchShippingAddresses =  usePatchShippingAddressesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePatchShippingAddressesHook>>>, {id: string;data: BodyType<PatchShippingAddressesBodies>}> = (props) => {
          const {id,data} = props ?? {};

          return  patchShippingAddresses(id,data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PatchShippingAddressesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePatchShippingAddressesHook>>>>
    export type PatchShippingAddressesMutationBody = BodyType<PatchShippingAddressesBodies>
    export type PatchShippingAddressesMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary 患者のお薬送付先情報を更新するAPI
 */
export const usePatchShippingAddresses = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchShippingAddressesHook>>>, TError,{id: string;data: BodyType<PatchShippingAddressesBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePatchShippingAddressesMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- メールアドレスとパスワードでサインインする。
- authFlowの値によって、返却されるレスポンスが異なる。
  - `USER_PASSWORD_AUTH`の場合、accessToken, idToken, refreshTokenをレスポンスとして返却する。
  - `REFRESH_TOKEN_AUTH`の場合、accessToken, idTokenをレスポンスとして返却する。
  - `CUSTOM_AUTH`の場合、accessToken, idTokenをレスポンスとして返却する。

# 画面
- https://www.figma.com/file/JuCk6DDPjoaDO4S4mdUm8A/Innojin?type=design&node-id=540-27580&t=rkKUZpJhTwybuV2q-4
- https://www.figma.com/file/JuCk6DDPjoaDO4S4mdUm8A/Innojin?type=design&node-id=540-24005&t=XiB03UhriN45ezId-4
 * @summary サインインAPI
 */
export const useLoginHook = () => {
        const login = useCustomClient<LoginResponseResponse>();

        return (
    loginBodies: BodyType<LoginBodies>,
 ) => {
        return login(
          {url: `/v1/login`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: loginBodies
    },
          );
        }
      }
    


export const useLoginMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useLoginHook>>>, TError,{data: BodyType<LoginBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useLoginHook>>>, TError,{data: BodyType<LoginBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const login =  useLoginHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useLoginHook>>>, {data: BodyType<LoginBodies>}> = (props) => {
          const {data} = props ?? {};

          return  login(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type LoginMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useLoginHook>>>>
    export type LoginMutationBody = BodyType<LoginBodies>
    export type LoginMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary サインインAPI
 */
export const useLogin = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useLoginHook>>>, TError,{data: BodyType<LoginBodies>}, TContext>, }
) => {
    
      const mutationOptions = useLoginMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- メールアドレスに認証コードを送信するAPI
 * @summary 多要素認証API
 */
export const useAuthChallengeHook = () => {
        const authChallenge = useCustomClient<LoginResponseResponse>();

        return (
    authChallengeBodies: BodyType<AuthChallengeBodies>,
 ) => {
        return authChallenge(
          {url: `/v1/authChallenge`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: authChallengeBodies
    },
          );
        }
      }
    


export const useAuthChallengeMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAuthChallengeHook>>>, TError,{data: BodyType<AuthChallengeBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAuthChallengeHook>>>, TError,{data: BodyType<AuthChallengeBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const authChallenge =  useAuthChallengeHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useAuthChallengeHook>>>, {data: BodyType<AuthChallengeBodies>}> = (props) => {
          const {data} = props ?? {};

          return  authChallenge(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type AuthChallengeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAuthChallengeHook>>>>
    export type AuthChallengeMutationBody = BodyType<AuthChallengeBodies>
    export type AuthChallengeMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary 多要素認証API
 */
export const useAuthChallenge = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAuthChallengeHook>>>, TError,{data: BodyType<AuthChallengeBodies>}, TContext>, }
) => {
    
      const mutationOptions = useAuthChallengeMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- パスワードをリセットするために事前に実行するAPI
 * @summary パスワードをリセットするためのAPI（指定したメールアドレスに認証コードが届く）
 */
export const useResetUserPasswordHook = () => {
        const resetUserPassword = useCustomClient<DataOKResponseResponse>();

        return (
    resetUserPasswordBodies: BodyType<ResetUserPasswordBodies>,
 ) => {
        return resetUserPassword(
          {url: `/v1/resetUserPassword`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: resetUserPasswordBodies
    },
          );
        }
      }
    


export const useResetUserPasswordMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useResetUserPasswordHook>>>, TError,{data: BodyType<ResetUserPasswordBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useResetUserPasswordHook>>>, TError,{data: BodyType<ResetUserPasswordBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const resetUserPassword =  useResetUserPasswordHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useResetUserPasswordHook>>>, {data: BodyType<ResetUserPasswordBodies>}> = (props) => {
          const {data} = props ?? {};

          return  resetUserPassword(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ResetUserPasswordMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useResetUserPasswordHook>>>>
    export type ResetUserPasswordMutationBody = BodyType<ResetUserPasswordBodies>
    export type ResetUserPasswordMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary パスワードをリセットするためのAPI（指定したメールアドレスに認証コードが届く）
 */
export const useResetUserPassword = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useResetUserPasswordHook>>>, TError,{data: BodyType<ResetUserPasswordBodies>}, TContext>, }
) => {
    
      const mutationOptions = useResetUserPasswordMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- 指定したパスワードでパスワードをリセットするAPI
 * @summary 指定したパスワードでパスワードをリセットするAPI
 */
export const useConfirmForgotPasswordHook = () => {
        const confirmForgotPassword = useCustomClient<DataOKResponseResponse>();

        return (
    confirmForgotPasswordBodies: BodyType<ConfirmForgotPasswordBodies>,
 ) => {
        return confirmForgotPassword(
          {url: `/v1/confirmForgotPassword`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: confirmForgotPasswordBodies
    },
          );
        }
      }
    


export const useConfirmForgotPasswordMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useConfirmForgotPasswordHook>>>, TError,{data: BodyType<ConfirmForgotPasswordBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useConfirmForgotPasswordHook>>>, TError,{data: BodyType<ConfirmForgotPasswordBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const confirmForgotPassword =  useConfirmForgotPasswordHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useConfirmForgotPasswordHook>>>, {data: BodyType<ConfirmForgotPasswordBodies>}> = (props) => {
          const {data} = props ?? {};

          return  confirmForgotPassword(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ConfirmForgotPasswordMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useConfirmForgotPasswordHook>>>>
    export type ConfirmForgotPasswordMutationBody = BodyType<ConfirmForgotPasswordBodies>
    export type ConfirmForgotPasswordMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary 指定したパスワードでパスワードをリセットするAPI
 */
export const useConfirmForgotPassword = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useConfirmForgotPasswordHook>>>, TError,{data: BodyType<ConfirmForgotPasswordBodies>}, TContext>, }
) => {
    
      const mutationOptions = useConfirmForgotPasswordMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- AccessTokenでサインアウトする。

# 画面
- https://www.figma.com/file/JuCk6DDPjoaDO4S4mdUm8A/Innojin?type=design&node-id=540-27580&t=rkKUZpJhTwybuV2q-4
- https://www.figma.com/file/JuCk6DDPjoaDO4S4mdUm8A/Innojin?type=design&node-id=540-24005&t=XiB03UhriN45ezId-4
 * @summary サインアウトAPI
 */
export const useLogoutHook = () => {
        const logout = useCustomClient<DataOKResponseResponse>();

        return (
    logoutBodies: BodyType<LogoutBodies>,
 ) => {
        return logout(
          {url: `/v1/logout`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: logoutBodies
    },
          );
        }
      }
    


export const useLogoutMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useLogoutHook>>>, TError,{data: BodyType<LogoutBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useLogoutHook>>>, TError,{data: BodyType<LogoutBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const logout =  useLogoutHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useLogoutHook>>>, {data: BodyType<LogoutBodies>}> = (props) => {
          const {data} = props ?? {};

          return  logout(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type LogoutMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useLogoutHook>>>>
    export type LogoutMutationBody = BodyType<LogoutBodies>
    export type LogoutMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary サインアウトAPI
 */
export const useLogout = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useLogoutHook>>>, TError,{data: BodyType<LogoutBodies>}, TContext>, }
) => {
    
      const mutationOptions = useLogoutMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- Authorizationヘッダーにjwtをセットし有効なjwtかを検証する
 * @summary ユーザー認証API
 */
export const useVerifyHook = () => {
        const verify = useCustomClient<DataOKResponseResponse>();

        return (
    
 signal?: AbortSignal
) => {
        return verify(
          {url: `/v1/verify`, method: 'get', signal
    },
          );
        }
      }
    

export const getVerifyQueryKey = () => [`/v1/verify`] as const;
  

    
export const useVerifyQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useVerifyHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useVerifyHook>>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useVerifyHook>>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getVerifyQueryKey();

  const verify =  useVerifyHook();
  
    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useVerifyHook>>>> = ({ signal }) => verify(signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type VerifyQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useVerifyHook>>>>
export type VerifyQueryError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

/**
 * @summary ユーザー認証API
 */
export const useVerify = <TData = Awaited<ReturnType<ReturnType<typeof useVerifyHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useVerifyHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useVerifyQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * # 仕様
 * @summary 認証コード再送API
 */
export const useVerifyResendAuthenticationCodeHook = () => {
        const verifyResendAuthenticationCode = useCustomClient<DataOKResponseResponse>();

        return (
    verifyResendAuthenticationCodeBodies: BodyType<VerifyResendAuthenticationCodeBodies>,
 ) => {
        return verifyResendAuthenticationCode(
          {url: `/v1/resendAuthenticationCode`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: verifyResendAuthenticationCodeBodies
    },
          );
        }
      }
    


export const useVerifyResendAuthenticationCodeMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useVerifyResendAuthenticationCodeHook>>>, TError,{data: BodyType<VerifyResendAuthenticationCodeBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useVerifyResendAuthenticationCodeHook>>>, TError,{data: BodyType<VerifyResendAuthenticationCodeBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const verifyResendAuthenticationCode =  useVerifyResendAuthenticationCodeHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useVerifyResendAuthenticationCodeHook>>>, {data: BodyType<VerifyResendAuthenticationCodeBodies>}> = (props) => {
          const {data} = props ?? {};

          return  verifyResendAuthenticationCode(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type VerifyResendAuthenticationCodeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useVerifyResendAuthenticationCodeHook>>>>
    export type VerifyResendAuthenticationCodeMutationBody = BodyType<VerifyResendAuthenticationCodeBodies>
    export type VerifyResendAuthenticationCodeMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary 認証コード再送API
 */
export const useVerifyResendAuthenticationCode = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useVerifyResendAuthenticationCodeHook>>>, TError,{data: BodyType<VerifyResendAuthenticationCodeBodies>}, TContext>, }
) => {
    
      const mutationOptions = useVerifyResendAuthenticationCodeMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 前提
- オンライン診察にはTwilioのProgrammable Videoを利用する
- Twilioを利用した通話機能では以下を考慮する必要がある
  - ユーザがルームに入るためには、アクセストークンが必要
  - アクセストークンは、以下の情報を使って動的に生成する必要あり(デフォルトの有効期限は1時間)
    - ACCOUNT SID(Twilioのアカウントに紐付いたID)
    - API KEY
    - API SECRET
    - IDENTITY(セッション中にユーザを識別するための値)
  - ルームに関する情報(=Video Grant)をアクセストークンのクレームとして設定できる
  - Video Grant: ルーム名のみ指定する。ルーム名には診察IDを利用する（e.g. `room-Consultations.id)`
  - IDENTITY: sub(=userID)とする
# 画面
- https://onl.tw/ehcV4JZ
- https://onl.tw/S7E9HZD
 * @summary オンライン診察用アクセストークン作成API
 */
export const usePostOnlineConsultationHook = () => {
        const postOnlineConsultation = useCustomClient<PostOnlineConsultationResponseResponse>();

        return (
    postOnlineConsultationBodies: BodyType<PostOnlineConsultationBodies>,
 ) => {
        return postOnlineConsultation(
          {url: `/v1/online_consultation`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: postOnlineConsultationBodies
    },
          );
        }
      }
    


export const usePostOnlineConsultationMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOnlineConsultationHook>>>, TError,{data: BodyType<PostOnlineConsultationBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOnlineConsultationHook>>>, TError,{data: BodyType<PostOnlineConsultationBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const postOnlineConsultation =  usePostOnlineConsultationHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostOnlineConsultationHook>>>, {data: BodyType<PostOnlineConsultationBodies>}> = (props) => {
          const {data} = props ?? {};

          return  postOnlineConsultation(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostOnlineConsultationMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostOnlineConsultationHook>>>>
    export type PostOnlineConsultationMutationBody = BodyType<PostOnlineConsultationBodies>
    export type PostOnlineConsultationMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary オンライン診察用アクセストークン作成API
 */
export const usePostOnlineConsultation = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOnlineConsultationHook>>>, TError,{data: BodyType<PostOnlineConsultationBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePostOnlineConsultationMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- 新規登録成功後に表示される基本情報登録画面で利用されるエンドポイント
- 前段で新規登録(サインアップ)されるため、このエンドポイントでは更新処理となる
- langの初期値は`JP`(今後英語や中国語も追加予定)

# 画面
- https://onl.la/yyBnQLh
 * @summary 患者情報更新API
 */
export const usePatchPatientHook = () => {
        const patchPatient = useCustomClient<SuccessResponse>();

        return (
    id: string,
    patchPatientBodyBodies: BodyType<PatchPatientBodyBodies>,
 ) => {
        return patchPatient(
          {url: `/v1/patient/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchPatientBodyBodies
    },
          );
        }
      }
    


export const usePatchPatientMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchPatientHook>>>, TError,{id: string;data: BodyType<PatchPatientBodyBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchPatientHook>>>, TError,{id: string;data: BodyType<PatchPatientBodyBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const patchPatient =  usePatchPatientHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePatchPatientHook>>>, {id: string;data: BodyType<PatchPatientBodyBodies>}> = (props) => {
          const {id,data} = props ?? {};

          return  patchPatient(id,data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PatchPatientMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePatchPatientHook>>>>
    export type PatchPatientMutationBody = BodyType<PatchPatientBodyBodies>
    export type PatchPatientMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary 患者情報更新API
 */
export const usePatchPatient = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchPatientHook>>>, TError,{id: string;data: BodyType<PatchPatientBodyBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePatchPatientMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- リクエストパラメーターのIDに紐づく患者情報を返す

# 画面
- https://onl.tw/YWGHAxS
 * @summary 患者情報取得API
 */
export const useGetPatientByIdHook = () => {
        const getPatientById = useCustomClient<GetPatientResponseResponse>();

        return (
    id: string,
 signal?: AbortSignal
) => {
        return getPatientById(
          {url: `/v1/patient/${id}`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetPatientByIdQueryKey = (id: string,) => [`/v1/patient/${id}`] as const;
  

    
export const useGetPatientByIdQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetPatientByIdHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPatientByIdHook>>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPatientByIdHook>>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPatientByIdQueryKey(id);

  const getPatientById =  useGetPatientByIdHook();
  
    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetPatientByIdHook>>>> = ({ signal }) => getPatientById(id, signal);
    
      
      
   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions}}

export type GetPatientByIdQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetPatientByIdHook>>>>
export type GetPatientByIdQueryError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

/**
 * @summary 患者情報取得API
 */
export const useGetPatientById = <TData = Awaited<ReturnType<ReturnType<typeof useGetPatientByIdHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPatientByIdHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetPatientByIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * # 仕様
- 問診を更新するAPI
 * @summary 問診更新API
 */
export const usePatchMedicalInterviewInstancesHook = () => {
        const patchMedicalInterviewInstances = useCustomClient<SuccessResponse>();

        return (
    id: string,
    patchMedicalInterviewInstancesBodies: BodyType<PatchMedicalInterviewInstancesBodies>,
 ) => {
        return patchMedicalInterviewInstances(
          {url: `/v1/medicalInterviewInstances/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchMedicalInterviewInstancesBodies
    },
          );
        }
      }
    


export const usePatchMedicalInterviewInstancesMutationOptions = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchMedicalInterviewInstancesHook>>>, TError,{id: string;data: BodyType<PatchMedicalInterviewInstancesBodies>}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchMedicalInterviewInstancesHook>>>, TError,{id: string;data: BodyType<PatchMedicalInterviewInstancesBodies>}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const patchMedicalInterviewInstances =  usePatchMedicalInterviewInstancesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePatchMedicalInterviewInstancesHook>>>, {id: string;data: BodyType<PatchMedicalInterviewInstancesBodies>}> = (props) => {
          const {id,data} = props ?? {};

          return  patchMedicalInterviewInstances(id,data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PatchMedicalInterviewInstancesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePatchMedicalInterviewInstancesHook>>>>
    export type PatchMedicalInterviewInstancesMutationBody = BodyType<PatchMedicalInterviewInstancesBodies>
    export type PatchMedicalInterviewInstancesMutationError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

    /**
 * @summary 問診更新API
 */
export const usePatchMedicalInterviewInstances = <TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchMedicalInterviewInstancesHook>>>, TError,{id: string;data: BodyType<PatchMedicalInterviewInstancesBodies>}, TContext>, }
) => {
    
      const mutationOptions = usePatchMedicalInterviewInstancesMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    /**
 * # 仕様
- zipcloudが提供しているAPIを利用する
  http://zipcloud.ibsnet.co.jp/doc/api

# 画面
- https://onl.la/yyBnQLh
 * @summary 郵便番号検索API
 */
export const useGetZipcodeHook = () => {
        const getZipcode = useCustomClient<GetZipcodeResponseResponse>();

        return (
    params: GetZipcodeParams,
 signal?: AbortSignal
) => {
        return getZipcode(
          {url: `/v1/zipcode`, method: 'get',
        params, signal
    },
          );
        }
      }
    

export const getGetZipcodeQueryKey = (params: GetZipcodeParams,) => [`/v1/zipcode`, ...(params ? [params]: [])] as const;
  

    
export const useGetZipcodeQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetZipcodeHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(params: GetZipcodeParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetZipcodeHook>>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetZipcodeHook>>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetZipcodeQueryKey(params);

  const getZipcode =  useGetZipcodeHook();
  
    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetZipcodeHook>>>> = ({ signal }) => getZipcode(params, signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetZipcodeQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetZipcodeHook>>>>
export type GetZipcodeQueryError = ErrorType<BadRequestResponse | InternalServerErrorResponse>

/**
 * @summary 郵便番号検索API
 */
export const useGetZipcode = <TData = Awaited<ReturnType<ReturnType<typeof useGetZipcodeHook>>>, TError = ErrorType<BadRequestResponse | InternalServerErrorResponse>>(
 params: GetZipcodeParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetZipcodeHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetZipcodeQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

/**
 * # 仕様
- キャンセル理由を取得するAPI
 * @summary キャンセル理由取得API
 */
export const useGetMasterCancelReasonsHook = () => {
        const getMasterCancelReasons = useCustomClient<GetMasterCancelReasonsResponseResponse>();

        return (
    
 signal?: AbortSignal
) => {
        return getMasterCancelReasons(
          {url: `/v1/masterCancelReasons`, method: 'get', signal
    },
          );
        }
      }
    

export const getGetMasterCancelReasonsQueryKey = () => [`/v1/masterCancelReasons`] as const;
  

    
export const useGetMasterCancelReasonsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetMasterCancelReasonsHook>>>, TError = ErrorType<InternalServerErrorResponse>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMasterCancelReasonsHook>>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMasterCancelReasonsHook>>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetMasterCancelReasonsQueryKey();

  const getMasterCancelReasons =  useGetMasterCancelReasonsHook();
  
    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetMasterCancelReasonsHook>>>> = ({ signal }) => getMasterCancelReasons(signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetMasterCancelReasonsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetMasterCancelReasonsHook>>>>
export type GetMasterCancelReasonsQueryError = ErrorType<InternalServerErrorResponse>

/**
 * @summary キャンセル理由取得API
 */
export const useGetMasterCancelReasons = <TData = Awaited<ReturnType<ReturnType<typeof useGetMasterCancelReasonsHook>>>, TError = ErrorType<InternalServerErrorResponse>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMasterCancelReasonsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetMasterCancelReasonsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}

