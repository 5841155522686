import React from "react";

import { SvgIcon, SvgIconProps } from '@mui/material';

import { ReactComponent as BusinessDay } from "../icons/svgs/businessDay.svg";
import { ReactComponent as Check } from "../icons/svgs/check.svg";
import { ReactComponent as CheckOutline } from "../icons/svgs/checkOutline.svg";
import { ReactComponent as CheckSimple } from "../icons/svgs/checkSimple.svg";
import { ReactComponent as Close } from "../icons/svgs/close.svg";
import { ReactComponent as CloseCircle } from "../icons/svgs/closeCircle.svg";
import { ReactComponent as Description } from "../icons/svgs/description.svg";
import { ReactComponent as Error } from "../icons/svgs/error.svg";
import { ReactComponent as Filter } from "../icons/svgs/filter.svg";
import { ReactComponent as InfoOutline } from "../icons/svgs/infoOutline.svg";
import { ReactComponent as Question } from "../icons/svgs/question.svg";
import { ReactComponent as RecordOff } from "../icons/svgs/recordOff.svg";
import { ReactComponent as RecordOn } from "../icons/svgs/recordOn.svg";
import { ReactComponent as Search } from "../icons/svgs/search.svg";
import { ReactComponent as Stethoscope } from "../icons/svgs/stethoscope.svg";
import { ReactComponent as ThreePointLeader } from "../icons/svgs/threePointLeader.svg";
import { ReactComponent as Trash } from "../icons/svgs/trash.svg";
import { ReactComponent as Zoom } from "../icons/svgs/zoom.svg";



const iconList: { [name: string]: any } = {
  businessDay: <BusinessDay/>,
  check: <Check/>,
  checkOutline: <CheckOutline/>,
  checkSimple: <CheckSimple/>,
  close: <Close/>,
  closeCircle: <CloseCircle/>,
  description: <Description/>,
  error: <Error/>,
  filter: <Filter/>,
  infoOutline: <InfoOutline/>,
  question: <Question/>,
  recordOff: <RecordOff/>,
  recordOn: <RecordOn/>,
  search: <Search/>,
  stethoscope: <Stethoscope/>,
  threePointLeader: <ThreePointLeader/>,
  trash: <Trash/>,
  zoom: <Zoom/>,
};

export type IconNameType = 
          | "businessDay"
          | "check"
          | "checkOutline"
          | "checkSimple"
          | "close"
          | "closeCircle"
          | "description"
          | "error"
          | "filter"
          | "infoOutline"
          | "question"
          | "recordOff"
          | "recordOn"
          | "search"
          | "stethoscope"
          | "threePointLeader"
          | "trash"
          | "zoom";

export interface Props extends React.HTMLProps<SvgIconProps> {
  iconName: IconNameType;
  color?: string;
  size: number;
}

export const Icon: React.FC<Props> = (props) => {
  const { iconName, color, size } = props;

  return <SvgIcon sx={{ color: color, fontSize: size }} >{iconList[iconName]}</SvgIcon>;
};