/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * inojin-stg-api
 * OpenAPI spec version: 1.0.0
 */

/**
 * - 期間の種類
- 以下を表現する
-
- 0: ◯日前から, 1: ◯週間前, 2: ◯ヶ月前 3: ◯週目

 */
export type AnswerDTOPeriodType = typeof AnswerDTOPeriodType[keyof typeof AnswerDTOPeriodType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AnswerDTOPeriodType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  null: null,
} as const;
