import { useEffect, useContext, useState } from "react";

import { AuthInfoContext } from "./authContextProvider";

import {
  useGetClinicHook,
  useGetClinicAccountsByEmailHook,
} from "../api-client";

export type ClinicInitData = {
  id: string;
  name: string;
  representativeId: string;
  createdAt: number;
  updatedAt: number;
  isDeleted: number;
  createdBy: string;
  updatedBy: string;
};

export const AccountStatus = {
  fistStep: 0,
  eachDayStep: 1,
  disableTimeStep: 2,
  periodStep: 3,
  done: 4,
} as const;

export type AccountStatusType = typeof AccountStatus[keyof typeof AccountStatus];


export const IsInitLoginedContextProvider = () => {
  const [clinicAccountStatus, setClinicAccountStatus] = useState<AccountStatusType>(4);
  const [clinicStatus, setClinicStatus] = useState<AccountStatusType>(4);
  const [authInfo] = useContext(AuthInfoContext);
  // API
  const getCliniclApi = useGetClinicHook();
  const getClinicAccountAPI = useGetClinicAccountsByEmailHook();

  // ログイン後初期設定情報が設定済みかどうかを確認する
  useEffect(() => {
    const getOwnerInfo = async (clinicId: string) => {
      const clinicRes = await getCliniclApi(clinicId);
      switch (clinicRes?.result?.initSettingStatus) {
        case "clinicInfo":
          setClinicStatus(AccountStatus.eachDayStep);
          break;
        case "eachDay":
          setClinicStatus(AccountStatus.disableTimeStep);
          break;
        case "disableTime":
          setClinicStatus(AccountStatus.periodStep);
          break;
        case "period":
          setClinicStatus(AccountStatus.done);
          break;
        default:
          setClinicStatus(AccountStatus.fistStep);
          break;
      }
    };

    const getClinicAccout = async () => {
      const clinicAccountRes = await getClinicAccountAPI(authInfo.email);
      switch (clinicAccountRes?.result?.initSettingStatus) {
        case "eachDay":
          setClinicAccountStatus(AccountStatus.disableTimeStep);
          break;
        case "disableTime":
          setClinicAccountStatus(AccountStatus.periodStep);
          break;
        case "period":
          setClinicAccountStatus(AccountStatus.done);
          break;
        default:
          setClinicAccountStatus(AccountStatus.eachDayStep);
          break;
      }
      await getOwnerInfo(clinicAccountRes?.result?.clinicId || "");
    };
    if (authInfo.role === "owner") {
      getClinicAccout();
    } else {
      getClinicAccout();
    }
    // eslint-disable-next-line
  }, [authInfo.email]);

  return [clinicAccountStatus, clinicStatus] as const;
};
