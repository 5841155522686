import {useEffect, useState} from "react";

import {RUM_APPLICATION_ID, RUM_POOL_ID} from "util/env";
import { isNotificationEnabled } from "util/featureFlag";

import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {AwsRum, AwsRumConfig} from "aws-rum-web";

import { requestForToken } from "./firebase";
import {Router} from "./pages/router";
import {AuthContextProvider} from "./provider/authContextProvider";
import {OwnClinicAccountContextProvider} from "./provider/ownClinicAccountProvider";
import {ThemeProviderWrap} from "./provider/themeProvider";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {

  // FIXME: stgで真っ白になるため一旦こちらを削除
  const [token, setToken] = useState<string | null | undefined>("");

  useEffect(() => {
    const registerSw = () => {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('../firebase-messaging-sw.js');
      } else {
        console.log("not serviceWorker")
      }
    } 
    const getToken = async () => {
      try {
        const initToken = await requestForToken();
        setToken(initToken);
      } catch (e) {
        console.error("Error getToken: ",e);
      }
    };

    if(isNotificationEnabled()) {
      registerSw();
      getToken();
    }
  }, []);
  console.log(token)

  useEffect(() => {
    if ("Notification" in window && Notification.permission === "default") {
      Notification.requestPermission().then((permission) => {
        console.log("Notification permission granted:", permission);
      });
    }

    try {
      const config: AwsRumConfig = {
        sessionSampleRate: 1,
        identityPoolId: RUM_POOL_ID,
        endpoint: "https://dataplane.rum.ap-northeast-1.amazonaws.com",
        telemetries: ["performance", "errors", "http"],
        allowCookies: true,
        enableXRay: false
      };

      const APPLICATION_ID: string = RUM_APPLICATION_ID;
      const APPLICATION_VERSION: string = '1.0.0';
      const APPLICATION_REGION: string = 'ap-northeast-1';

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const awsRum: AwsRum = new AwsRum(
        APPLICATION_ID,
        APPLICATION_VERSION,
        APPLICATION_REGION,
        config
      );
    } catch (error) {
      console.error("Failed to initialize AWS RUM:", error);
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      {/* devツール */}
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      <AuthContextProvider>
        <OwnClinicAccountContextProvider>
          <ThemeProviderWrap>
            <Router/>
          </ThemeProviderWrap>
        </OwnClinicAccountContextProvider>
      </AuthContextProvider>
    </QueryClientProvider>
  );
}

export default App;
