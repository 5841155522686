/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * inojin-stg-api
 * OpenAPI spec version: 1.0.0
 */

export type AdminUpdateUserStatusBodiesUserStatus = typeof AdminUpdateUserStatusBodiesUserStatus[keyof typeof AdminUpdateUserStatusBodiesUserStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdminUpdateUserStatusBodiesUserStatus = {
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
} as const;
