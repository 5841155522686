import React, { useContext } from "react";

import { AuthInfoContext } from "./authContextProvider";

import {
  GetClinicAccountsByEmailResponseResponseResult,
  GetClinicAccountsByEmailResponseResponseResultRole,
  useGetClinicAccountsByEmail,
} from "../api-client";

export interface OwnClinicAccountContextType {
  clinicAccount: GetClinicAccountsByEmailResponseResponseResult;
}

export const OwnClinicAccountContext =
  React.createContext<OwnClinicAccountContextType>({
    clinicAccount: {
      id: "",
      clinicId: "",
      role: GetClinicAccountsByEmailResponseResponseResultRole.staff,
      updatedAt: 0,
      createdAt: 0,
      updatedBy: "",
      isDeleted: 0,
      email: "",
      createdBy: "",
      name: undefined,
      clinicAccountNumber: ""
    }
  });

type Props = {
  children?: React.ReactNode;
};

// AppContext にセッター関数を登録するためのコンポーネント
export const OwnClinicAccountContextProvider: React.FC<Props> = ({
  children,
}) => {
  const [authInfo] = useContext(AuthInfoContext);
  const { data } = useGetClinicAccountsByEmail(authInfo.email);

  const context: OwnClinicAccountContextType = useContext(
    OwnClinicAccountContext
  );

  const newContext: OwnClinicAccountContextType = {
    clinicAccount: data?.result || context.clinicAccount,
  };

  return (
    <OwnClinicAccountContext.Provider value={newContext}>
      {children}
    </OwnClinicAccountContext.Provider>
  );
};
